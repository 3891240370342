import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.providerListObserver();
  }

  providerListObserver() {
    this.config = { attributes: true, childList: true, subtree: true };
    this.providerListContainer = document.querySelector('#provider-info-sidebar');
    this.providerCountContainer = document.querySelector('#provider-found-count h2');
    this.mapZeroState = document.getElementById("map-zero-state");
    this.mapZeroStateOutOfState = document.getElementById("map-zero-state-out-of-state");

    this.observer = new MutationObserver(() => {
    this.provider = this.providerListContainer.querySelectorAll('.marker-provider-info-wrapper');
      if (!this.provider.length) {
        this.showZeroState();
      } else {
        this.showProviders();
      }
    })
    this.observer.observe(this.providerListContainer, this.config);
  }

  showZeroState() {
    if (window.zerostate == 'out-of-state' && !window.text_search_address) {
      this.mapZeroStateOutOfState.classList.add('show');
    } else {
      this.mapZeroState.classList.add('show');
    }
    this.providerCountContainer.innerHTML = '';
  }

  showProviders() {
    if (this.mapZeroState.classList.contains('show') || this.mapZeroStateOutOfState.classList.contains('show')) {
      this.mapZeroState.classList.remove('show');
      this.mapZeroStateOutOfState.classList.remove('show')
    }
    this.providerCountContainer.innerHTML = this.provider.length + " Daycares near you";
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.providerGalleryCarousel()
  }

  providerGalleryCarousel(){
    $('.daycare-profiles-parent-view-photo-carousel').slick({
      nextArrow: $('.daycare-profiles-parent-view-carousel-next'),
      prevArrow: $('.daycare-profiles-parent-view-carousel-previous'),
      slidesToShow: 3,
      infinite: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            centerMode: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    });
  }
}

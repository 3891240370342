import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let frame = document.getElementById('traffic_log_form');
    if (window.latlng !== undefined && document.getElementById('new_traffic_log') ) {
      let coordinates = window.latlng.lat + "," + window.latlng.lng
      document.getElementById('traffic_log_latlng').value = coordinates
      document.getElementById('traffic_log_original_search').value = document.getElementById('address').value
    }

    document.addEventListener("turbo:frame-render", () => {
      this.connect()
    })

    document.addEventListener("turbo:submit-end", () => {
      setTimeout(() => {
        frame.src = frame.dataset.src;
      }, 5000);
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (window.innerWidth > 992) {
      this.setupCheckboxFunctionality();
    }
    // use same binding reference for adding and removal
    this.boundHandleResize = this.handleResize.bind(this);

    window.addEventListener('resize', this.boundHandleResize);
  }

  disconnect() {
    window.removeEventListener('resize', this.boundHandleResize);
  }

  setupCheckboxFunctionality() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"], input[type="radio"]');
    const filterContainer = document.getElementById('provider-filters');
    const filterClear = document.getElementById('clear-filters');
    const filterDrawers = document.querySelectorAll('.filter .menu')
    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', () => {
        const anyCheckboxChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);

        if (anyCheckboxChecked) {
          filterContainer.classList.add('filters-active');
          filterClear.classList.remove("d-none");
        } else {
          filterContainer.classList.remove('filters-active');
          filterClear.classList.add("d-none");
        }
      });
    });
    filterDrawers.forEach(function (drawer) {
      if (drawer.classList.contains("show")) {
        drawer.classList.remove('show')
      }
    });
  }

  handleResize() {
    if (window.innerWidth > 992) {
      this.setupCheckboxFunctionality();
    }
  }
}


document.addEventListener("turbo:frame-render", () => {
  addBootstrapFormValidation()
})

document.addEventListener("turbo:load", () => {
  addBootstrapFormValidation()
})

const addBootstrapFormValidation = () => {
  'use strict'
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')
  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
}

window.onRecaptchaLoad = function() {
  document.dispatchEvent(new CustomEvent('recaptcha:loaded'));
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
   this.providerReviewsCarousel();
  }

  providerReviewsCarousel(){
    $('.carousel').slick({
      nextArrow: $('.carousel-next'),
      prevArrow: $('.carousel-previous'),
      slidesToShow: 2,
      infinite: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            centerMode: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    });
  }
}

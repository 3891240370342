import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.providerRatesCarousel();
  }

  providerRatesCarousel(){
    $('.price-openings-carousel').slick({
      arrows:false,
      infinite: false,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: true,
            appendDots: $('.dots'),
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            dots: true,
            appendDots: $('.dots'),
            slidesToShow: 1,
          }
        }
      ]
    });
  }
}

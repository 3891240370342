import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.moveProviderFoundCount();
    window.addEventListener("resize", this.moveProviderFoundCount.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.moveProviderFoundCount.bind(this));
  }

  moveProviderFoundCount() {
    const screenWidth = window.innerWidth;
    const providerFoundCount = document.getElementById("provider-found-count");
    const mapZeroState = document.getElementById("map-zero-state");
    const mapOutsideZeroState = document.getElementById('map-zero-state-out-of-state')
    const sidebar = document.querySelector("aside.sidebar");
    const article = document.querySelector("article.content");

    if (providerFoundCount === null) return

    if (screenWidth < 992) {
      if (sidebar.previousElementSibling !== providerFoundCount) {
        sidebar.parentElement.insertBefore(providerFoundCount, sidebar);
      }
      if (article.firstElementChild !== mapZeroState) {
        article.prepend(mapZeroState);
        article.prepend(mapOutsideZeroState)
      }
    } else {
      if (sidebar.firstElementChild !== providerFoundCount) {
        sidebar.prepend(providerFoundCount);
        providerFoundCount.after(mapZeroState)
        providerFoundCount.after(mapOutsideZeroState)
      }
    }
  }
}

import {
  Controller
} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['checkbox', 'counter'];

  connect() {
    this.syncCheckboxes();
    this.observeCheckboxChanges();
    this.handleSingleRadioSelection();
  }

  observeCheckboxChanges() {
    this.element.querySelectorAll('.desktop-filter input[type="checkbox"], .desktop-filter input[type="radio"]:checked').forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
    });
    this.element.querySelectorAll('.filter-drawer input[type="checkbox"], .filter-drawer input[type="radio"]:checked').forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange.bind(this));
    });
  }

  handleCheckboxChange(event) {
    this.syncCheckboxes();
    this.updateFilterCount();
  }

  syncCheckboxes() {
    const isDesktop = window.innerWidth >= 992;

    const checkboxSuffix = isDesktop ? '' : '_mobile';
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"], input[type="radio"]:checked');

    checkboxes.forEach(checkbox => {
      const isMobileCheckbox = checkbox.id.includes('_mobile');

      if (checkbox.checked && !isMobileCheckbox && checkbox.id.endsWith(checkboxSuffix)) {
        const mobileCheckboxId = checkbox.id + '_mobile';
        const mobileCheckbox = document.getElementById(mobileCheckboxId);
        if (mobileCheckbox) {
          mobileCheckbox.checked = true;
        }
      } else if (checkbox.checked && isMobileCheckbox && checkbox.id.endsWith(checkboxSuffix)) {
        const desktopCheckboxId = checkbox.id.replace('_mobile', '');
        const desktopCheckbox = document.getElementById(desktopCheckboxId);
        if (desktopCheckbox) {
          desktopCheckbox.checked = true;
        }
      }
    });
  }

  updateFilterCount() {
    this.element.querySelectorAll('.filter').forEach(filterElement => {
      const menuCheckboxes = filterElement.querySelectorAll('.menu input[type="checkbox"], .menu input[type="radio"]:checked');
      const checkedCount = Array.from(menuCheckboxes).filter(checkbox => checkbox.checked).length;
      const counter = filterElement.querySelector('.filter-counter');
      if (counter) {
        counter.textContent = checkedCount > 0 ? `(${checkedCount})` : '';
      }
    });
  }

  clearCheckedInputs() {
    this.checkedInputs = []
  }

  handleSingleRadioSelection() {
    // This prevents multiple options from being selected
    let currentCheckedChildCareType = null;
  
    function handleRadioChange(radio, currentChecked, name) {
      // Ensure we are only dealing with the correct group of radio buttons
      if (radio.checked && currentChecked && currentChecked !== radio && radio.name === name) {
        // Uncheck the previous radio button
        currentChecked.checked = false;
        filterProcessor.addCheckboxFilter(currentChecked);
      }
      return radio.checked ? radio : null;
    }
  
    // child care type radio buttons
    document.querySelectorAll('input[name="child_care_type"]').forEach(function (radio) {
      radio.addEventListener('change', function () {
        currentCheckedChildCareType = handleRadioChange(this, currentCheckedChildCareType, 'child_care_type');
        filterProcessor.addCheckboxFilter(this);
      });
    });
  }
  
}

import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

Dropzone.autoDiscover = false;
export default class extends Controller {
  static values = { providerId: Number }

  connect(){
    this.providerApproved();
    this.compareAndReturnHeight();

    this.addProviderGalleryImageDropzone()
    this.addProviderProfileDropzone()
  }

  initialize () {
    this.providerIsApproved = this.data.get("providerApprovedValue");
    this.providerGalleryDropzoneElement = document.getElementById("provider-gallery-dropzone");
    this.providerProfileDropzoneElement = document.getElementById("profile-image-dropzone");
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
  }

  // Disable Unapproved Providers Section ---->

  providerApproved () {
    if (this.providerIsApproved === "false") {
      this.disableFormForUnapprovedProvider();
    }
  }

  disableFormForUnapprovedProvider () {
    const profileDetailsFormElements = document.querySelectorAll('.daycare-provider-input-section-anchors input, .daycare-provider-input-section-anchors textarea, .daycare-provider-input-section-anchors button, .daycare-provider-input-section-anchors select');

    profileDetailsFormElements.forEach(function(element) {
      element.disabled = true;
    });

    const addAndRemoveLinks = document.querySelectorAll('.remove_fields, .add_fields');

    addAndRemoveLinks.forEach(function(link) {
      link.style.display = "none";
    });
  }

  disableProviderGalleryDropzone () {  
    const providerGalleryDropzone = Dropzone.forElement("#provider-gallery-dropzone");
    
      if (providerGalleryDropzone) {
        providerGalleryDropzone.disable();
      }
    }

  disableProfileImageDropzone () {  
    const profileImageDropzone = Dropzone.forElement("#profile-image-dropzone");
      
      if (profileImageDropzone) {
        profileImageDropzone.disable();
      }
    }

  // <---- Disable Unapproved Providers Section

  compareAndReturnHeight () {
    // left and right columns
    let profileColumns = document.querySelectorAll('.profile-column-equalizer')
    // container that hold the forms on the right side
    let scrollContent = document.querySelector('.daycare-provider-input-section-anchors')

    let heights = [];

    profileColumns.forEach((column) => {
      heights.push(column.offsetHeight)
    })

    let shortest = Math.min.apply( Math, heights );

    let total = shortest - this.sectionNavHeight();

    scrollContent.style.height = total.toString() + "px";
  }

  sectionNavHeight(){
    // links: About, Schedule, Prices, Qualifications
    let navHeight = document.getElementById('profile-sections-nav').offsetHeight
    return navHeight - 8;
  }

  // Drop Zone Section ---->

  dropzoneThumbnailErrors () {
    const errorPreviews = document.querySelectorAll(".dz-image");
    errorPreviews.forEach(element => {
      element.style.border = ".2rem solid red";
    });
 
    // hide dropzone X button
    const errorMarkers = document.querySelectorAll(".dz-error-mark");
    errorMarkers.forEach(element => {
        element.style.display = "none";
    });
  }

    // Provider Gallery Image Section ---->

  addProviderGalleryImageDropzone () {
    const controller = this
  
    if (controller.providerGalleryDropzoneElement) {
      new Dropzone(controller.providerGalleryDropzoneElement, {
        url: `/users/providers/${controller.providerIdValue}`,
        paramName: "provider[provider_gallery_images_attributes][][image]",
        maxFilesize: 2,
        maxFiles: 8,
        acceptedFiles: "image/jpeg, image/png",
        addRemoveLinks: true,
        parallelUploads: 1,
        headers: { 'Accept': "text/vnd.turbo-stream.html" },
        init: function() {
          const dropzoneInstance = this;
  
          dropzoneInstance.on("success", function(file, response) {
            dropzoneInstance.removeFile(file);
            Turbo.renderStreamMessage(response);
          });
  
          dropzoneInstance.on("error", function(_, errorMessage, __) {
            console.error("Error uploading file:", errorMessage);

            controller.dropzoneThumbnailErrors()
          });
        },
        sending: function(_, __, formData) {
          formData.append("authenticity_token", controller.csrfToken);
          formData.append("_method", "patch");
        }
      });
    }
    if (this.providerIsApproved === "false") {
      this.disableProviderGalleryDropzone();
    }
  }

  removeProviderGalleryImage(event) {
    const imageId = event.target.dataset.imageId;

    this.handleRemoveGalleryImageLoadingState(event)

    fetch(`/users/providers/${this.providerIdValue}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken,
        "Accept": "text/vnd.turbo-stream.html",
      },
      body: JSON.stringify({ provider: { provider_gallery_images_attributes: [{ id: imageId, _destroy: true }] } }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text(); // Convert the ReadableStream to text
    })
    .then(turboStreamContent => {
      Turbo.renderStreamMessage(turboStreamContent);
    })
    .catch(error => {
      console.error("Error in removeProviderGalleryImage:", error);
    });
  }

  handleRemoveGalleryImageLoadingState (event) {
    const removeImageButton = event.currentTarget
    const galleryImageToDelete = event.target.closest('.gallery-image');
    const removeImageLoadingState = galleryImageToDelete.querySelector('.removing-image-loading-state');
    
    removeImageButton.style.display = "none"
    removeImageLoadingState.style.display = "block"
  }

    // <---- Provider Gallery Image Section

    // Profile Image Section ---->

  addProviderProfileDropzone () {
    const controller = this

    if (controller.providerProfileDropzoneElement) {
      new Dropzone(controller.providerProfileDropzoneElement, {
        url: `/users/providers/${controller.providerIdValue}`,
        paramName: "provider[profile_image]",
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: "image/jpeg, image/png",
        addRemoveLinks: true,
        headers: { 'Accept': "text/vnd.turbo-stream.html" },
        init: function() {
          const dropzoneInstance = this;

          dropzoneInstance.on("success", function(file, response) {
            dropzoneInstance.removeFile(file);
            Turbo.renderStreamMessage(response);

            controller.providerProfileDropzoneElement.style.setProperty("display", "none");
          });

          dropzoneInstance.on("error", function(_, errorMessage, __) {
            console.error("Error uploading file:", errorMessage);
            controller.dropzoneThumbnailErrors()
          });
        },
        sending: function(_, __, formData) {
          formData.append("authenticity_token", controller.csrfToken);
          formData.append("_method", "patch");
        }
      });
    }
    if (this.providerIsApproved === "false") {
      this.disableProfileImageDropzone();
    }
  }

  removeProviderProfileImage () {
    fetch(`/users/providers/${this.providerIdValue}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken,
        "Accept": "text/vnd.turbo-stream.html",
      },
      body: JSON.stringify({ provider: { remove_profile_image: 1 } } ),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text(); // Convert the ReadableStream to text
    })
    .then(turboStreamContent => {
      Turbo.renderStreamMessage(turboStreamContent);

      this.providerProfileDropzoneElement.style.setProperty("display", "block");
    })
  }
    // <---- Profile Image Section

  // <---- Drop Zone Section
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller{
  connect() {
    this.initializeFilterCounters();

    const menu = this.element.querySelector('.filter-drawer .menu');
    if (menu) {
      const observer = new MutationObserver(() => {
        this.initializeFilterCounters();
      });

      observer.observe(menu, {
        childList: true,
        subtree: true
      });
    }
  }

  initializeFilterCounters() {

    let label = document.querySelectorAll('.label')

    label.forEach(function (el) {
      if (el.id != "") {
        if (!document.getElementById(el.id + "-filter-count")) {
          let counterContainerElement = document.createElement("span")
          counterContainerElement.id = el.id + "-filter-count";
          counterContainerElement.className = "filter-counter ps-1";
          let counterContainer = document.getElementById(el.id);
          let iconElement = counterContainer.querySelector('i');
          counterContainer.insertBefore(counterContainerElement, iconElement);
        }
      }
    })

    const filterGroups = document.querySelectorAll('.filter');

    filterGroups.forEach(function (filterGroup) {
      const label = filterGroup.querySelector('.label');
      if (label.id != "") {
        const counterContainer = filterGroup.querySelector('.filter-counter');
        const checkboxes = filterGroup.querySelectorAll('input[type="checkbox"], input[type="radio"]');
        checkboxes.forEach(function (checkbox) {
          checkbox.addEventListener('change', function () {
            // This timeout prevents the checkbox count from being updated
            // before the childcare type selections are toggled
            setTimeout(() => { 
              let checkedCount = 0;
              checkboxes.forEach(function (cb) {
                if (cb.checked) {
                  checkedCount++;
                }
              });
              if (checkedCount > 0) {
                counterContainer.textContent = '(' + checkedCount + ')';
                if (counterContainer.id !== "mobile-filter-filter-count") {
                  filterGroup.style.border = "3px solid #7B4393"
                }
              } else {
                counterContainer.textContent = '';
                filterGroup.style.border = "unset"
              }
            }, 0)
          });
        });
      }
    });
  }
}
